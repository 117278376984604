import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //全局变量
  state: {
    idx:0,
  },
  //get方法
  getters: {
    getIdx(state) {
      return state.idx
    }
  },
  //set方法
  mutations: {
    saveIdx(state,value) {
      state.idx=value
    }
  },
  actions: {
  },
  modules: {
  }
})
