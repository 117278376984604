<template>
    <div class="juxiuOffice">
      <div class="navBar">
        <div class="juxiuLogo">
        </div>
        <menuNav></menuNav>
        <div class="tolinkUs" @click="cooperateUs">与我们合作</div>
      </div>
      <div class="content">
        <router-view></router-view>
        <!-- <officebodyContent></officebodyContent> -->
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import officebodyContent from '@/components/officebodyContent.vue'
  import menuNav from '@/components/menuNav.vue'
  export default {
    name: 'HomeView',
    components: {
      officebodyContent,menuNav
    },
    methods:{
      cooperateUs(){
        this.$router.push('/CaseTogether')
      }
    }
  }
  </script>
  <style lang="scss">
  .juxiuOffice{
    height: 100%;
    width: 100%;
    overflow: hidden;
    .navBar{
      height: 80px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 3px 25px 5px rgba(181,183,203,0.25);
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .juxiuLogo{
        position: absolute;
        background: url('/public/img/navlogo.png');
        background-size: 100% 100%;
        width: 116px;
        height: 32px;
        left: 15%;
        top: calc(50% - 16px);
      }
      .tolinkUs{
        cursor: pointer;
        height: 100%;
        width: 6.67%;
        height: 100%;
        background: linear-gradient(269deg, #FF8D36 0%, #F2BD61 100%);
        font-size: 16px;
        font-family: Source Han Sans CN Medium;
        color: #FFFFFF;
        line-height: 80px;
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: -36px;
            left: -100px;
            background: white;
            width: 50px;
            height: 125px;
            opacity: 20%;
            transform: rotate(-45deg);
        }
        &:hover::after{
            left: 120%;
            transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
        }
      }
    }
    .content{
      height: calc(100% - 80px);
      overflow: hidden;
      width: 100%;
    }
  }
  </style>
  