import Vue from 'vue'
import {Swipe, SwipeItem, Lazyload, Empty, Form, Field, Button, Toast} from 'vant'
import 'vant/lib/index.css'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Lazyload, {
    lazyComponent: true,
})
Vue.use(Empty)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.prototype.$toast = Toast;
