<template>
  <div class="sidebar-mobile">
    <div class="sidebarList">
      <div class="menus">
        <!--一级菜单-->
        <div :class="['one-item', idx === 0 ? 'item-checked' : '']" @click="choose('/',0)">
          <div class="icon" v-if="idx === 0"></div>
          <div :class="['title', idx === 0 ? 'title-checked' : '']">首页</div>
        </div>
        <!--二级菜单-->
        <div class="one-item-second" @click="showDown()">
          <div class="title">解决方案</div>
            <div :class="['down-icon',flag === true ? 'rotate':'rotate2']"></div>
        </div>
        <div class="two-item" v-if="flag === true">
          <div  v-for="(item,index) in navArr" :key="index" 
            @click="choose(item.url,item.idx)"
            :class="['child-items', idx === item.idx ? 'item-checked' : '']">
              <div class="icon" v-if="idx === item.idx"></div>
              <div class="title" :style="idx === item.idx ? 'color:rgb(249, 190, 89);' : ''">{{ item.name }}</div>
          </div>
        </div>
        <!--一级菜单-->
        <div :class="['one-item', idx === 7 ? 'item-checked' : '']" @click="choose('/caseDetails',7)">
          <div class="icon" v-if="idx === 7"></div>
          <div :class="['title', idx === 7 ? 'title-checked' : '']">应用案例</div>
        </div>
        <!--一级菜单-->
        <div :class="['one-item', idx === 8 ? 'item-checked' : '']" @click="choose('/informationDynamic',8)">
          <div class="icon" v-if="idx === 8"></div>
          <div :class="['title', idx === 8 ? 'title-checked' : '']">资讯动态</div>
        </div>
        <!--一级菜单-->
        <div :class="['one-item', idx === 9 ? 'item-checked' : '']" @click="choose('/aboutUs',9)">
          <div class="icon" v-if="idx === 9"></div>
          <div :class="['title', idx === 9 ? 'title-checked' : '']">关于聚秀</div>
        </div>
			</div>

      <!--与我们合作-->
      <div class="contactUs" @click="contactUs()">
        <div class="flex-box">
          <div class="title">与我们合作</div>
          <div class="icon"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      currentIndex: 0,
      navArr: [
            {
              name: "数智国资",
              url: "/stateOwnedAssets",
              idx: 1,
            },
            {
              name: "智慧国企",
              url: "/stateOwnedEnterprise",
              idx: 2,
            },
            {
              name: "资产云",
              url: "/assetCloud",
              idx: 3,
            },
            {
              name: "智慧好管家",
              url: "/goodHousekeeper",
              idx: 4,
            },
            {
              name: "智慧监管",
              url: "/intelligentSupervision",
              idx: 5,
            },
            {
              name: "人工智能应用",
              url: "/ai",
              idx: 6,
            },
      ],
      idx: 0,//当前选择的item
      flag: false//是否显示二级菜单
    };
  },
  mounted(){
    this.idx=this.$store.state.idx//store取出当前的idx
  },
  methods: {
    handleCommand(command) {
      this.$router.push(command.url);
      this.currentIndex = 1;
    },
    selectItem(row, index) {
      this.$router.push(row.url);
      this.currentIndex = index;
    },
    choose(url,idx){
      this.$router.push(url)
      this.idx=idx
      this.$store.commit('saveIdx',this.idx)//存idx到store中
      this.$emit('flag', false)
    },
    showDown(){
      this.flag=!this.flag
    },
    contactUs(){
      this.$router.push('/workWithUs')
      this.$store.commit('saveIdx',-1)//存idx到store中
      this.$emit('flag', false)
    },
  },
};
</script>

<style lang="scss" scoped>
//导航栏
.sidebar-mobile {
  width: 40%;
  height: 100%;
  position: fixed;
  right: 0;
  background: #ffffff;
	z-index: 999999999999999999999;
  // box-shadow: 10px 0px 20px 0px rgba(134, 159, 178, 0.36);

  .sidebarList {
    position: relative;
    height: 100%;
    overflow: hide;
    background-color: #FFF;

    .menus{
      height: 100%;
			overflow-x: auto;
      .item-checked{
        background: #FFEBD6;
        color: #FFEBD6;
      }
      .one-item{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 13px;

        .icon{
          width: 4px;
          height: 60px;
          background: #FD8E16;
        }

        .title{
          width: auto;
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 33px;
          color:  #212121;
          margin-left: 52px;
        }

        .title-checked{
          background: #FFEBD6;
          color: rgb(249, 190, 89);
        }

        .down-icon{
          background-image: url("/public/imgmobile/down.png");
  	  		background-size: 100% 100%;
          margin-left: 35%;
          width: 16px;
          height: 16px;
        }

        //旋转
        .rotate{
          transform: rotate(0deg);
          transition: all .5s;
        }
        //旋转
        .rotate2{
          transform: rotate(180deg);
          transition: all .5s;
        }
      }
      .one-item-second{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 13px;

        .icon{
          width: 4px;
          height: 60px;
          background: #FD8E16;
        }

        .title{
          width: auto;
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 33px;
          color:  #212121;
          margin-left: 52px;
        }

        .title-checked{
          background: #FFEBD6;
          color: rgb(249, 190, 89);
        }

        .down-icon{
          background-image: url("/public/imgmobile/down.png");
  	  		background-size: 100% 100%;
          margin-left: 35%;
          width: 16px;
          height: 16px;
        }

        //旋转
        .rotate{
          transform: rotate(0deg);
          transition: all .5s;
        }
        //旋转
        .rotate2{
          transform: rotate(180deg);
          transition: all .5s;
        }
      }

      .one-item:hover{
        background-color: #FFEBD6;
      }

      .two-item{
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        
        .child-items{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 60px;
          margin-top: 13px;

            .item-checked{
              background: #FFEBD6;
              color: #FFEBD6;
            }
            .icon{
                width: 4px;
                height: 60px;
                background: #FD8E16;
              }
            .title{
                width: auto;
                height: 33px;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 33px;
                color: #212121;
                margin-left: 66px;
              }
        }
      }
    }
  }
  .contactUs{
    width: 100%;
    height: 60px;
    background-image: url("/public/imgmobile/contact_bg.png");
    background-size: 100% 100%;
        
    position:  absolute;
    bottom:0;
    left: 0;

    .flex-box{
      display:flex;
      flex-direction: row;
      align-items: center;
      .title{
        width: 111px;
        height: 22px;
        font-size: 24px;
        font-family: PangMenZhengDao;
        color: #FFFFFF;
        line-height: 22px;

        margin-left: 30%;
        margin-top:5%;
        display: relative;      
      }
      .icon{
        background-image: url("/public/imgmobile/right_icon.png");
        background-size: 100% 100%;
        width: 24px;
        height: 24px;
            
        margin-left: 3%;
        margin-top: 4%;
        display: relative;
      }
    }
  }
}
</style>
