<template>
  <div class="footerBlock">
    <div class="company_log"></div>
    <div class="company_connect">
        <div class="connect_us">联系我们</div>
        <div class="company_info">
            <div class="company_row company_name" >杭州聚秀科技有限公司</div>
            <div class="company_row"><div class="point_icon"></div><span class="info">杭州市萧山区杭州湾信息港E栋602室</span></div>
            <div class="company_row"><div class="dianhua_icon"></div><span class="info">0571-82829806 / 4000802658</span></div>
            <a class="company_row"><div class="email_icon"></div><a href="mailto:business@hzjuxiu.com" class="info">mailto:business@hzjuxiu.com</a></a>
            <a target="_blank" rel="noopener" href="https://beian.miit.gov.cn" class="company_row compant_btmRight"><img  src="@/assets/guard.png" alt=""><span class="info">浙ICP备17037851号</span></a>
        </div>
    </div>
    
    <div class="divide"></div>
    <div class="company_button">
        <div class="dowithus" @click="contactUs()">与我们合作</div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        contactUs(){//联系我们
            this.$router.push('/caseTogether')
        },
    }
}
</script>

<style lang="scss" scoped>
.divide{
    position: absolute;
    width: 76%;
    height: 1px;
    opacity: 0.4;
    border: 1px solid #868E99;
    bottom: 52px;
}
.company_name{
    font-size: 24px !important;
}
.compant_btmRight{
    text-decoration: none;
    position: absolute;
    right: 7.15%;
    display: flex;
    align-items: center;
    bottom: 20px;
    img{
        width:11px;
        height: 12px;
    }
}
.footerBlock{
    width: 100%;
    height: 100%;
    padding: 2.92% 16.67%;
    display: flex;
    position: relative;
    .company_log{
        width: 198px;
        height: 55px;
        background: url('/public/img/jxlogo.png');
        background-size: 100% 100%;
    }
    .company_connect{
        // display: flex;
        // flex-direction: column;
        // width: 844px;
        padding-left: 97px;
        text-align: left;
        .connect_us{
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 33px;
            
        }
        .company_info{
            margin-top: 17px;
            .company_row{
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                color: #C0C4CC;
                line-height: 25px;
                letter-spacing: 2px;
                display: flex;
                .info{
                    color: #C0C4CC;
                    text-decoration: none;
                    padding-left: 15px;
                }
            }
            .company_row + .company_row{
                padding-top: 20px;
            }
            .point_icon,.dianhua_icon,.email_icon{
                background: url('/public/img/icon/dingwei.png');
                background-size: 100% 100%;
                width: 20px;
                height: 22px;
            }
            .dianhua_icon{
                background: url('/public/img/icon/dianhua.png');
                background-size: 100% 100%;
            }
            .email_icon{
                background: url('/public/img/icon/youxiang.png');
                background-size: 100% 100%;
                width: 23px;
                height: 18px;
                margin-top: 2px;
            }
        }
    }
    .company_button{
        position: absolute;
        right: 7.15%;
        top: 50%;
        transform: translate(0,-50%);
        display: flex;
        // height: 100%;
        align-items: center;
        justify-content: center;
        .dowithus{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
            box-shadow: 0px 2px 10px 0px rgba(196,165,145,0.25);
            // width: 124px;
            padding: 10px;
            height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            color: #FFFFFF;
            line-height: 22px;
            overflow: hidden;
            position: relative;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: -36px;
                left: -100px;
                background: white;
                width: 50px;
                height: 125px;
                opacity: 20%;
                transform: rotate(-45deg);
            }
            &:hover::after{
                left: 120%;
                transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
            }
        }
    }
}

</style>