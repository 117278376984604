<template>
  <div class="mainpageMobile">
    <div class="mainView">
        <div class="juxiuTitle">数智治理与智慧软件解决方案服务商</div>
    </div>
    <div class="businessInfo">
        <div class="business_title">
            <div class="title">1+N：业务发展方向</div>
            <div class="subtext">多跨融合 智慧治理</div>
        </div>
        <div class="jxsmartClound">
            <div class="smartName">聚秀数智云</div>
        </div>
        <div class="smartBg">
          <div class="business_item business_item-1">
            数智国资
          </div>
          <div class="business_item business_item-2">
            智慧国企
          </div>
          <div class="business_item business_item-3">
            资产云
          </div>
          <div class="business_item business_item-4">
            人工智能应用
          </div>
          <div class="business_item business_item-5">
            智慧监管
          </div>
          <div class="business_item business_item-6">
            智慧好管家
          </div>
        </div>
    </div>
    <div class="advantage">
        <div class="business_title">
            <div class="title">万千客户的信任与选择</div>
            <div class="subtext">全国各省市布局，服务政府国企数字化转型</div>
        </div>
        <div class="business_info">
            <div class="business_block">
                <div class="title"><span class="numberStyle">10</span><span class="wordStyle">年专业领域</span></div>
                <div class="subtext">软件行业经验丰富，提供最精准的解决方案</div>
            </div>
            <div class="business_block">
                <div class="title"><span class="numberStyle">4</span><span class="unit">w+</span><span class="wordStyle">合作用户</span></div>
                <div class="subtext">广受业内认可，品牌值得信赖</div>
            </div>
        </div>
    </div>
    <div class="thenews">
        <div class="business_title">
            <div class="title">资讯动态</div>
            <div class="subtext">关注聚秀发展，聚焦行业动态</div>
        </div>
        <div class="content">
            <!--轮播图-->
            <van-swipe :autoplay="3000" :show-indicators="false" @change="onChange" class="swipe" ref="jumpto">
				<van-swipe-item class="swipe-item-box" v-for="(item,index) in newsArr" :key="index" @click="onCardClick(item)">
					<img class="item-pic" :src="require('/public/img/dynamic/' + item.img)"/>
					<div class="vertical-box">
                        <div class="text-date">{{item.time}}</div>
                        <div class="text" :style="current === index ? 'color:#FF795B' : ''">{{item.title}}</div>
                    </div>
				</van-swipe-item>
                <!-- 自定义指示器(插槽形式) -->
                <template #indicator>
                    <div class="custom-indicator" :class="{active: current === 0}" @click="changeItem(0)"></div>
                    <div class="custom-indicator-2" :class="{active: current === 1}" @click="changeItem(1)"></div>
                    <div class="custom-indicator-3" :class="{active: current === 2}" @click="changeItem(2)"></div>
                </template>
			</van-swipe>
        </div>
        <div class="indicators">
			    <div v-for="(item, index) in dotsList" :key="index" :class="{active: checkActive(index)}"></div>
            </div>
        <div class="content_more" @click="gotoMore()">
            <div class="more_btn">查看更多 </div>
        </div>
    </div>
  </div>
</template>

<script>
import {micData} from '@/views/dynamic/micData'
export default {
    data(){
        return{
            businessArr:['数智国资','智慧国企','资产云','人工智能应用','智慧监管','智慧好管家'],
            newsArr:[
                // {date:'2022-11-12',text:'浙江省国资委推出国资交易质量提升“十件事”',url:'news1.png'},
                // {date:'2022-11-12',text:'完善平台智能录入、统计、检索、客服、审核、监测和线上签约、线上鉴证、资金结算等功能，提高平台智能化水平',url:'news2.png'},
                // {date:'2022-11-12',text:'浙江省国资委推出国资交易质量提升“十件事”',url:'news3.png'}
            ],
            current: 0,//当前的轮播图的下标
            dotsList: [0,1,2],//指示器的下标数组
        }
    },
    mounted(){
       this.newsArr = micData.slice(0,3)//取出新闻数据
       console.log(this.current)
    },
    methods:{
        gotoMore(){
            this.$router.push('/informationDynamic')
            this.$store.commit('saveIdx',8)//存idx到store中
        },
        onCardClick(item) {
            window.open(item.url)
        },
        onChange(index) {
            this.current = index;
        },
        changeItem(index) {
            this.current = index;
            this.$refs.jumpto.swipeTo(index);//调用Swipe的swipeTo方法跳转到指定图
        },
        checkActive(index) {
			return index === this.current;// current为监听的轮播index
        },
    }
}
</script>

<style lang="scss" scoped>
.mainpageMobile{
    width: 100%;

    .mainView{
        background: url('/public/imgmobile/mainimg.png');
        background-size: 100% 100%;
        width: 100%;
        height: 251px;
        position: relative;
        .juxiuTitle{
            position: absolute;
            left: 5%;
            top: 43%;
            transform: translate(0,-50%);
            font-size: 28px;
            font-family: PangMenZhengDao;
            color: #333131;
            line-height: 65px;
            letter-spacing: 2px;
        }
    }
    .businessInfo{
        padding-top: 45px;
        width: 100%;
        height: 640px;
        position: relative;
        display: flex;
        justify-content: center;
        .business_title{
            .title{
                font-size: 24px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 33px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 16px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
            }
        }
        .jxsmartClound{
            position: absolute;
            background: url('/public/imgmobile/jxszy.png');
            background-size: 100% 100%;
            top: 192px;
            left: calc(50% - 298px);
            width: 617px;
            height: 230px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .smartName{
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 33px;
                margin-bottom: 65px;
            }
        }
        .smartBg{
            .business_item{
                cursor: pointer;
                width: 180px;
                height: 44px;
                display: flex;
                padding-left: 54px;
                padding-bottom: 4px;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
                background: url('/public/img/businessBlock.png');
                background-size: 100% 100%;
                position: absolute;
                &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: -36px;
                        left: -100px;
                        width: 50px;
                        height: 125px;
                        opacity: 3%;
                        transform: rotate(-45deg);
                }
                &:hover::after{
                    left: 120%;
                    transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
                }
            }
			.business_item-1{
				top: 240px;
				left: 67px;
			}
			.business_item-2{
				top: 320px;
				left: 22px;
			}
			.business_item-3{
				top: 394px;
				left: 150px;
			}
		    .business_item-4{
				top: 394px;
				left: 428px;
			}
		    .business_item-5{
				top: 320px;
				left: 556px;
			}
			.business_item-6{
				top: 240px;
				left: 518px;
			}
        }
    }
    .advantage{
        width: 100%;
        height: 640px;
        background: url('/public/imgmobile/ouradvantage.png');
        background-size: 100% 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .business_title{
            padding-top: 60px;
            .title{
                font-size: 32px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 18px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
            }
        }
        .business_info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 6.9%;
            padding-top: 90px;
            .business_block{
                text-align: left;
                .title{
                    font-size: 16px;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                    color: #212121;
                    line-height: 22px;
                }
                .unit{
                    color: #FF795B;
                    font-family: Source Han Sans CN Bold;
                    font-weight: 600;
                    font-size: 22px;
                }
                .numberStyle{
                    font-size: 40px;
                    font-family: Source Han Sans CN Bold;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FF795B;
                    line-height: 56px;
                }
                .subtext{
                    font-size: 14px;
                    font-family: Source Han Sans CN Regular;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }
                .wordStyle{
                    padding-left: 12px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
            .business_block + .business_block{
                padding-top: 60px;
            }
        }
    }
    .thenews{
        width: 100%;
        height: 720px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 60px;
        .business_title{
            padding-top: 60px;
            .title{
                font-size: 32px;
                font-family: Source Han Sans CN Medium;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
            }
            .subtext{
                padding-top: 11px;
                font-size: 18px;
                font-family: Source Han Sans CN Normal;
                font-weight: 400;
                color: #666666;
            }
        }
        .content{
            width: 100%;
            height: 710px;
            padding: 7.5% 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            //轮播图
            .swipe{
                height:414px;

				.swipe-item-box{
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					
					.item-pic{
                        width: 542px;
						height: 320px;
					}
					
                    .vertical-box{
                        width: 535px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        
                        .text-date{
                            position: absolute;
                            width: auto;
                            height: 20px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 20px;
                            margin-top: 15px;
                        }
                        .text{
                            text-align: left;//文本左对齐
                            position: relative;
                            margin-top: 47px;
                            left:0;
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            line-height: 22px;
                            width: 100%;
                            height: auto;

                            word-wrap:break-word;//换行
                            overflow: hidden;//溢出隐藏
                        }
                    }
				}

                //指示器第一个圆点
                .custom-indicator{
                    position: absolute;
                    display: flex;
                    flex-direction:row;
                    left: 333px;
                    // bottom: 30px;
                    bottom: 6px;
    
                    width: 10px;
					height: 10px;
					border-radius: 10px;
					background:  linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
					opacity: 0.4;

					&.active {
						background-color: linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
						opacity: 1;
					}
                }
                //指示器第二个圆点
                .custom-indicator-2{
                    position: absolute;
                    display: flex;
                    flex-direction:row;
                    left: 368px;
                    bottom: 6px;
    
                    width: 10px;
					height: 10px;
					border-radius: 10px;
					background:  linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
					opacity: 0.4;

					&.active {
						background-color: linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
						opacity: 1;
					}
                }
                //指示器第三个圆点
                .custom-indicator-3{
                    position: absolute;
                    display: flex;
                    flex-direction:row;
                    left: 403px;
                    bottom: 6px;
    
                    width: 10px;
					height: 10px;
					border-radius: 10px;
					background:  linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
					opacity: 0.4;

					&.active {
						background-color: linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
						opacity: 1;
					}
                }
			}
        }

        //查看更多
        .content_more{
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            right: 12.7%;
            top:86.4%;
            .more_btn{
                cursor: pointer;
                width: 144px;
                height: 60px;
                background: url('/public/img/morebtnbg.png');
                background-size: 100% 100%;
                font-size: 16px;
                font-family: Source Han Sans CN Medium;
                color: #FFFFFF;
                line-height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: -28px;
                    left: -60px;
                    background: white;
                    width: 50px;
                    height: 125px;
                    opacity: 20%;
                    transform: rotate(-45deg);
                }
                &:hover::after{
                    left: 120%;
                    transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
                }
            }
        }
    }
}
</style>