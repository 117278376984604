var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainpage"},[_vm._m(0),_c('div',{staticClass:"businessInfo"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"smartBg"},[_vm._l((_vm.businessArr),function(e,index){return [_c('div',{key:index,staticClass:"business_item",style:({'left':index<3?`calc(${index*20}% - ${index*30+90}px)`:'unset','right':index>2?`calc(${(index-3)*20}% - ${index*40-30}px)`:'unset',
                  'bottom':`calc(50% - ${(index>2?index-3:index)*(22)}%` })},[_vm._v(" "+_vm._s(e)+" ")])]})],2)]),_vm._m(3),_c('div',{staticClass:"thenews"},[_vm._m(4),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_photo"},[_c('el-carousel',{attrs:{"trigger":"click"},on:{"change":_vm.change}},_vm._l((_vm.newsArr),function(item,index){return _c('el-carousel-item',{key:index},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('/public/img/dynamic/' + item.img),"fit":"fit"}})],1)}),1)],1),_c('div',{staticClass:"right_content"},[_c('div',{staticClass:"right_block"},_vm._l((_vm.newsArr),function(item,index){return _c('div',{key:index,staticClass:"content_row"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.time))]),_c('div',{staticClass:"newscontent",style:(_vm.currentIndex==index?'color:#FF795B':''),on:{"click":function($event){return _vm.onCardClick(item)}}},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"content_more",on:{"click":_vm.gotoMore}},[_c('div',{staticClass:"more_btn"},[_vm._v(" 查看更多 ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainView"},[_c('div',{staticClass:"juxiuTitle"},[_vm._v("数智治理与数智云服务的先行者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business_title"},[_c('div',{staticClass:"title"},[_vm._v("1+N：领域架构")]),_c('div',{staticClass:"subtext"},[_vm._v("多跨融合 智慧治理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jxsmartClound"},[_c('div',{staticClass:"smartName"},[_vm._v("聚秀数智云")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage"},[_c('div',{staticClass:"business_title"},[_c('div',{staticClass:"title"},[_vm._v("万千客户的信任与选择")]),_c('div',{staticClass:"subtext"},[_vm._v("全国各省市布局，服务政府国企数字化转型")])]),_c('div',{staticClass:"business_info"},[_c('div',{staticClass:"business_block"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"numberStyle"},[_vm._v("10")]),_c('span',{staticClass:"wordStyle"},[_vm._v("年专业领域")])]),_c('div',{staticClass:"subtext"},[_vm._v("软件行业经验丰富，提供最精准的解决方案")])]),_c('div',{staticClass:"business_block"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"numberStyle"},[_vm._v("4")]),_c('span',{staticClass:"unit"},[_vm._v("w+")]),_c('span',{staticClass:"wordStyle"},[_vm._v("合作用户")])]),_c('div',{staticClass:"subtext"},[_vm._v("广受业内认可，品牌值得信赖")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business_title"},[_c('div',{staticClass:"title"},[_vm._v("资讯动态")]),_c('div',{staticClass:"subtext"},[_vm._v("关注聚秀发展，聚焦行业动态")])])
}]

export { render, staticRenderFns }