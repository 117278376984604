<template>
  <div class="officebodyContent-mobile">
    <div class="mainContent">
        <mainpage></mainpage>
    </div>
    
    
  </div>
</template>

<script>
import footerBlock from '@/components/mobile/footerBlock.vue'
import mainpage from '@/components/mobile/mainpage.vue'
export default {
    components:{footerBlock,mainpage}
}   
</script>

<style lang="scss" scoped>

.officebodyContent-mobile{
    width: 100%;
    height: 100%;
    overflow: auto;
    .mainContent{
        min-height: 1000px;
        width: 100%;
    }
    .footer{
        height: 337px;
        width: 100%;
        background: #0D1E33;
    }
}


</style>