<template>
  <div class="menuNav">
    <template v-for="(item, index) in navArr">
      <div
        v-if="!item.children"
        class="menuItem"
        :key="index"
        @click="selectItem(item, index)"
        :class="currentIndex == index ? 'curIndex' : ''"
      >
        {{ item.name }}
      </div>
      <div
        v-else
        :class="currentIndex == index ? 'curIndex' : ''"
        class="menuItem"
        :key="index"
      >
        <el-dropdown
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link"> 解决方案 </span>
          <el-dropdown-menu slot="dropdown" popper-class="solution-submenu">
            <el-dropdown-item
              v-for="(item2, index2) in item.children"
              :command="item2"
              :key="index2"
              >{{ item2.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <!-- <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleSelect" router>
			<el-menu-item index="/">首页</el-menu-item>
			<el-submenu popper-class="solution-submenu">
				<template slot="title">解决方案</template>
				<el-menu-item index="/stateOwnedAssets">数智国资</el-menu-item>
				<el-menu-item index="/stateOwnedEnterprise">智慧国企</el-menu-item>
				<el-menu-item index="/assetCloud">资产云</el-menu-item>
				<el-menu-item index="/goodHousekeeper">智慧好管家</el-menu-item>
				<el-menu-item index="/intelligentSupervision">智慧监管</el-menu-item>
				<el-menu-item index="/ai">人工智能</el-menu-item>
			</el-submenu>
			<el-menu-item index="/caseDetails">应用案例</el-menu-item>
			<el-menu-item>新闻中心</el-menu-item>
			<el-menu-item index="/aboutUs">关于聚秀</el-menu-item>
			<el-menu-item>联系我们</el-menu-item>
			<el-menu-item>应用市场</el-menu-item>
		</el-menu> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      currentIndex: 0,
      navArr: [
        {
          name: "首页",
          url: "/",
        },
        {
          name: "解决方案",
          url: "/stateOwnedAssets",
          children: [
            {
              name: "数智国资",
              url: "/stateOwnedAssets",
            },
            {
              name: "智慧国企",
              url: "/stateOwnedEnterprise",
            },
            {
              name: "资产云",
              url: "/assetCloud",
            },
            {
              name: "智慧好管家",
              url: "/goodHousekeeper",
            },
            {
              name: "智慧监管",
              url: "/intelligentSupervision",
            },
            {
              name: "人工智能应用",
              url: "/ai",
            },
          ],
          menuItem: true,
        },
        {
          name: "应用案例",
          url: "/caseDetails",
        },
        {
          name: "资讯动态",
          url: "/caseDynamic",
        },
        {
          name: "关于聚秀",
          url: "/aboutUs",
        },
      ],
    };
  },
  methods: {
    handleCommand(command) {
      this.$router.push(command.url);
      this.currentIndex = 1;
    },
    selectItem(row, index) {
      this.$router.push(row.url);
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item:hover{
    background: linear-gradient(90deg, #ff795b 0%, #ff8e36 100%) !important;
    border-radius: 4px;
    color: #ffffff !important;
  }
::v-deep .el-dropdown {
  height: 100%;
  font-size: 0.07rem;
  font-family: Source Han Sans CN Medium;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // font-size: 0.1rem;
  
}
.dropIsHover{
    background: linear-gradient(90deg, #ff795b 0%, #ff8e36 100%) !important;
    border-radius: 4px;
    color: #ffffff !important;
}
.solution-submenu {
  .el-menu--popup {
    text-align: center;
    min-width: 100px !important;
  }
  .el-menu-item {
    font-size: 14px !important;
    font-family: PingFangSC-Medium, PingFang SC !important;
    font-weight: bold !important;
    color: #333333 !important;
    margin-bottom: 10px !important;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  .el-menu-item:hover {
    background: linear-gradient(90deg, #ff795b 0%, #ff8e36 100%) !important;
    border-radius: 4px;
    color: #ffffff !important;
  }
}
.menuNav {
  max-width: 70%;
  // height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 56px;
  margin-top: 20px;
  .menuItem {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Medium;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 4px #fff solid;
    &:hover {
      border-bottom: 4px #ffa92b solid;
      font-weight: 700;
    }
  }
  .menuItem + .menuItem {
    margin-left: 56px;
  }
  .curIndex {
    border-bottom: 4px #ffa92b solid;
    font-weight: 700;
  }
  .el-menu {
    .el-submenu__title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .el-menu-item {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .el-submenu.is-active {
      .el-submenu__title {
        border-bottom: 2px solid #ffa92b !important;
      }
    }
    .el-menu-item.is-active {
      border-bottom: 2px solid #ffa92b !important;
    }
  }
}
</style>
