<template>
  <div class="footer-block">
    <div class="company_connect">
        <div class="connect_us">联系我们</div>
        <div class="company_info">
            <div class="company_row company_name" >杭州聚秀科技有限公司</div>
            <div class="company_row"><div class="point_icon"></div><span class="info">杭州市萧山区杭州湾信息港E栋602室</span></div>
            <div class="company_row"><div class="dianhua_icon"></div><span class="info">0571-82829806 / 4000802658</span></div>
            <a class="company_row"><div class="email_icon"></div><a href="mailto:business@hzjuxiu.com" class="info">business@hzjuxiu.com</a></a>
						<a target="_blank" rel="noopener" href="https://beian.miit.gov.cn" class="company_row compant_btmRight"><img  src="@/assets/guard.png" alt=""><span class="info">浙ICP备17037851号</span></a>
        </div>
    </div>

    <div class="company_log"></div>
    
    <div class="divide"></div>
    <div class="company_button">
        <div class="dowithus" @click="contactUs()">与我们合作</div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        contactUs(){//联系我们
            this.$router.push('/workWithUs')
            this.$store.commit('saveIdx',-1)//存idx到store中
        },
    }
}
</script>

<style lang="scss" scoped>
.divide{
    position: absolute;
    width: 90%;
    height: 1px;
    opacity: 0.4;
    border: 1px solid #868E99;
    bottom: 65px;
    left: 5%;
}
.company_name{
    font-size: 16px !important;
    opacity: 0.5;
}
.compant_btmRight{
    text-decoration: none;
    position: absolute;
    right: 4.8%;
    display: flex;
    align-items: center;
    bottom: 36px;
    img{
        width:11px;
        height: 11px;
    }
}
.footer-block{
    width: 100%;
    height: auto;
    // padding: 2.92% 16.67%;
    padding: 0;
    display: flex;
    position: relative;
    padding-bottom: 80px;
		background: #0D1E33;
		margin-bottom: 80px;

    
    .company_connect{
        // display: flex;
        // flex-direction: column;
        // width: 844px;
        
        text-align: left;
        margin-bottom: 10px;
        margin-top: 40px;
        padding-left: 40px;
        .connect_us{
            font-size: 20px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 22px;
            margin-top: 10px;
        }
        .company_info{
            margin-top: 17px;
            .company_row{
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                color: #C0C4CC;
                line-height: 20px;
                letter-spacing: 1px;
                display: flex;
                .info{
                    color: #C0C4CC;
                    text-decoration: none;
                    padding-left: 10px;
                    opacity: 0.5;
                }
            }
            .company_row + .company_row{
                padding-top: 10px;
            }
            .point_icon,.dianhua_icon,.email_icon{
                background: url('/public/img/icon/dingwei.png');
                background-size: 100% 100%;
                width: 18px;
                height: 22px;
            }
            .email_icon{
                margin-left: -4px;
            }
            .dianhua_icon{
                background: url('/public/img/icon/dianhua.png');
                background-size: 100% 100%;
            }
            .email_icon{
                background: url('/public/img/icon/youxiang.png');
                background-size: 100% 100%;
                width: 23px;
                height: 18px;
                margin-top: 2px;
            }
        }
    }
    .company_log{
        width: 115px;
        height: 32px;
        background: url('/public/img/jxlogo.png');
        background-size: 100% 100%;
        position: absolute;
        right: 40px;
        top: 50px;
    }
    .company_button{
        position: absolute;
        right: 5.2%;
        top: 50%;
        transform: translate(0,-50%);
        display: flex;
        // height: 100%;
        align-items: center;
        justify-content: center;
        width: 124px;
        height: 40px;
        .dowithus{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(90deg, #FF795B 0%, #FF8E36 100%);
            box-shadow: 0px 2px 10px 0px rgba(196,165,145,0.25);
            width: 124px;
            padding: 10px;
            height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            color: #FFFFFF;
            line-height: 22px;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 2px 10px 0px rgba(196,165,145,0.25);
            border-radius: 2px;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: -36px;
                left: -100px;
                background: white;
                width: 50px;
                height: 125px;
                opacity: 20%;
                transform: rotate(-45deg);
                box-shadow: 0px 2px 10px 0px rgba(196,165,145,0.25);
                border-radius: 2px;
            }
            &:hover::after{
                left: 120%;
                transition: all 2s cubic-bezier(0.3, 1, 0.2, 1);
            }
        }
    }
}

</style>