import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../layout/index'
import HomeViewMobile from '@/views/mobile/layout/index'

Vue.use(VueRouter)

const pcRoutes = [
  {
    path: '/',
    component: HomeView,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import(/* webpackChunkName: "views" */ '@/views/HomeView.vue'),
      },
      {
        path: '/stateOwnedAssets',
        name: 'stateOwnedAssets',
        component: () => import(/* webpackChunkName: "views" */ '@/views/StateOwnedAssets.vue'),
      },
      {
        path: '/stateOwnedEnterprise',
        name: 'stateOwnedEnterprise',
        component: () => import(/* webpackChunkName: "views" */ '@/views/StateOwnedEnterprise.vue'),
      },
      {
        path: '/assetCloud',
        name: 'assetCloud',
        component: () => import(/* webpackChunkName: "views" */ '@/views/AssetCloud.vue'),
      },
      {
        path: '/intelligentSupervision',
        name: 'intelligentSupervision',
        component: () => import(/* webpackChunkName: "views" */ '@/views/IntelligentSupervision.vue'),
      },
      {
        path: '/goodHousekeeper',
        name: 'goodHousekeeper',
        component: () => import(/* webpackChunkName: "views" */ '@/views/goodHousekeeper.vue'),
      },
      {
        path: '/ai',
        name: 'ai',
        component: () => import(/* webpackChunkName: "views" */ '@/views/AI.vue'),
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "views" */ '@/views/AboutUs.vue'),
      },
      {
        path: '/caseDetails',
        name: 'caseDetails',
        component: () => import(/* webpackChunkName: "views" */ '@/views/CaseDetails.vue'),
        children: [
          {
            path: '/caseHZGZ',
            name: 'caseHZGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseHZGZ.vue'),
          },
          {
            path: '/caseWZGZ',
            name: 'caseWZGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseWZGZ.vue'),
          },
          {
            path: '/caseHuZhouGZ',
            name: 'caseHuZhouGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseHuZhouGZ.vue'),
          },
          {
            path: '/caseYHGZ',
            name: 'caseYHGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseYHGZ.vue'),
          },
          {
            path: '/caseLAGZ',
            name: 'caseLAGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseLAGZ.vue'),
          },
          {
            path: '/caseXSGZ',
            name: 'caseXSGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseXSGZ.vue'),
          },
          {
            path: '/caseBJGZ',
            name: 'caseBJGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseBJGZ.vue'),
          },
          {
            path: '/caseDYGZ',
            name: 'caseDYGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseDYGZ.vue'),
          },
          {
            path: '/caseLHGZ',
            name: 'caseLHGZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseLHGZ.vue'),
          },
          {
            path: '/caseHouse',
            name: 'caseHouse',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseHouse.vue'),
          },
          {
            path: '/caseWenzhou',
            name: 'caseWenzhou',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseWenzhou.vue'),
          },
          {
            path: '/caseXinjiang',
            name: 'caseXinjiang',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseXinjiang.vue'),
          },
          {
            path: '/caseQuzhou',
            name: 'caseQuzhou',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseQuzhou.vue'),
          },
          {
            path: '/caseXiangtan',
            name: 'caseXiangtan',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseXiangtan.vue'),
          },
          {
            path: '/caseSupervisionHZ',
            name: 'caseSupervisionHZ',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseSupervisionHZ.vue'),
          },
          {
            path: '/caseSupervisionXS',
            name: 'caseSupervisionXS',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseSupervisionXS.vue'),
          },
          {
            path: '/caseSupervisionJS',
            name: 'caseSupervisionJS',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseSupervisionJS.vue'),
          },
          {
            path: '/caseSupervisionLA',
            name: 'caseSupervisionLA',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseSupervisionLA.vue'),
          },
          {
            path: '/caseSupervisionSY',
            name: 'caseSupervisionSY',
            component: () => import(/* webpackChunkName: "views" */ '@/views/CaseSupervisionSY.vue'),
          },
        ],
      },
      {
        path: '/caseDynamic',
        name: 'caseDynamic',
        component: () => import(/* webpackChunkName: "views" */ '@/views/CaseDynamic.vue'),
      },
      {
        path: '/caseIframe',
        name: 'caseIframe',
        component: () => import(/* webpackChunkName: "views" */ '@/views/CaseIframe.vue'),
      },
      {
        path: '/caseTogether',
        name: 'caseTogether',
        component: () => import(/* webpackChunkName: "views" */ '@/views/CaseTogether.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: '建设中',
    meta: {
      keepAlive: false,
      i18n: 'dashboard',
    },
    component: () => import(/* webpackChunkName: "views" */ '@/views/404.vue'),
  },
]

const mobileRoutes = [
  {
    path: '/',
    component: HomeViewMobile,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'moblieHome',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/HomeView.vue'),
      },
      {
        path: '/stateOwnedAssets',
        name: 'moblieStateOwnedAssets',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/StateOwnedAssets.vue'),
      },
      {
        path: '/stateOwnedEnterprise',
        name: 'moblieStateOwnedEnterprise',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/StateOwnedEnterprise.vue'),
      },
      {
        path: '/assetCloud',
        name: 'moblieAssetCloud',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/AssetCloud.vue'),
      },
      {
        path: '/intelligentSupervision',
        name: 'moblieIntelligentSupervision',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/IntelligentSupervision.vue'),
      },
      {
        path: '/goodHousekeeper',
        name: 'moblieGoodHousekeeper',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/goodHousekeeper.vue'),
      },
      {
        path: '/ai',
        name: 'moblieAi',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/AI.vue'),
      },
      {
        path: '/aboutUs',
        name: 'moblieAboutUs',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/AboutUs.vue'),
      },
      {
        path: '/caseDetails',
        name: 'moblieCaseDetails',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/CaseDetails.vue'),
      },
      {
        path: '/informationDynamic',
        name: 'moblieInformationDynamic',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/InformationDynamic.vue'),
      },
      {
        path: '/workWithUs',
        name: 'moblieWorkWithUs',
        component: () => import(/* webpackChunkName: "views" */ '@/views/mobile/WorkWithUs.vue'),
      },
    ],
  },
]
// 判断是否是移动端
function handleIsMobile() {
  return window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

let routes = []
if (handleIsMobile()) {
  // 如果是移动端，跳转到移动端页面
  routes = mobileRoutes
} else if (!handleIsMobile()) {
  // 如果是PC端，跳转到PC端页面
  routes = pcRoutes
}

console.log('%c [ router-handleIsMobile ]-262', 'font-size:13px; background:pink; color:#bf2c9f;', handleIsMobile())

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
